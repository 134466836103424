import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { filter, map, throttleTime } from 'rxjs/operators';

// eslint-disable-next-line @angular-eslint/prefer-standalone
@Component({
  selector: 'app-search',
  template: `
  <div class="search">
    <div class="search__query">
      <label class="search__label" for="search">Search</label>
      <input class="search__field"
             type="text"
             id="search"
             [formControl]="term"
             placeholder="Search"
             autocomplete="off">
    </div>
    <div class="search__answer">
      <ng-container *ngFor="let result of results">
        <a [routerLink]="result.slice(0,1)" [fragment]="result" (click)="clear()">{{result}}</a>
      </ng-container>
    </div>
  </div>
`,
  styleUrls: [],
  encapsulation: ViewEncapsulation.None,
  standalone: false
})
export class SearchComponent implements OnInit {
  term = new UntypedFormControl('');
  results = [];
  icons = [
    'acid',
    'acorn',
    'alien_fire',
    'all_for_one',
    'alligator_clip',
    'ammo_bag',
    'anchor',
    'angel_wings',
    'ankh',
    'anvil',
    'apple',
    'aquarius',
    'arcane_mask',
    'archer',
    'archery_target',
    'arena',
    'aries',
    'arrow_cluster',
    'arrow_flights',
    'arson',
    'aura',
    'aware',
    'axe_swing',
    'axe',
    'ball',
    'barbed_arrow',
    'barrier',
    'bat_sword',
    'battered_axe',
    'batteries',
    'battery_0',
    'battery_100',
    'battery_25',
    'battery_50',
    'battery_75',
    'battery_black',
    'battery_negative',
    'battery_positive',
    'battery_white',
    'batwings',
    'beam_wake',
    'bear_trap',
    'beer',
    'beetle',
    'bell',
    'biohazard',
    'bird_claw',
    'bird_mask',
    'blade_bite',
    'blast',
    'blaster',
    'bleeding_eye',
    'bleeding_hearts',
    'bolt_shield',
    'bomb_explosion',
    'bombs',
    'bone_bite',
    'bone_knife',
    'book',
    'boomerang',
    'boot_stomp',
    'bottle_vapors',
    'bottled_bolt',
    'bottom_right',
    'bowie_knife',
    'bowling_pin',
    'brain_freeze',
    'brandy_bottle',
    'bridge',
    'broadhead_arrow',
    'broadsword',
    'broken_bone',
    'broken_bottle',
    'broken_heart',
    'broken_shield',
    'broken_skull',
    'bubbling_potion',
    'bullets',
    'burning_book',
    'burning_embers',
    'burning_eye',
    'burning_meteor',
    'burst_blob',
    'butterfly',
    'campfire',
    'cancel',
    'cancer',
    'candle_fire',
    'candle',
    'cannon_shot',
    'capitol',
    'capricorn',
    'carrot',
    'castle_emblem',
    'castle_flag',
    'cat',
    'chain',
    'cheese',
    'chemical_arrow',
    'chessboard',
    'chicken_leg',
    'circle_of_circles',
    'circular_saw',
    'circular_shield',
    'cloak_and_dagger',
    'clockwork',
    'clover',
    'clovers_card',
    'clovers',
    'cluster_bomb',
    'coffee_mug',
    'cog_wheel',
    'cog',
    'cold_heart',
    'compass',
    'corked_tube',
    'crab_claw',
    'cracked_helm',
    'cracked_shield',
    'croc_sword',
    'crossbow',
    'crossed_axes',
    'crossed_bones',
    'crossed_pistols',
    'crossed_sabres',
    'crossed_swords',
    'crown_of_thorns',
    'crown',
    'crowned_heart',
    'crush',
    'crystal_ball',
    'crystal_cluster',
    'crystal_wand',
    'crystals',
    'cubes',
    'cut_palm',
    'cycle',
    'daggers',
    'daisy',
    'dead_tree',
    'death_skull',
    'decapitation',
    'defibrillate',
    'demolish',
    'dervish_swords',
    'desert_skull',
    'diamond',
    'diamonds_card',
    'diamonds',
    'dice_five',
    'dice_four',
    'dice_one',
    'dice_six',
    'dice_three',
    'dice_two',
    'dinosaur',
    'divert',
    'diving_dagger',
    'double_team',
    'doubled',
    'dragon_breath',
    'dragon_wing',
    'dragon',
    'dragonfly',
    'drill',
    'dripping_blade',
    'dripping_knife',
    'dripping_sword',
    'droplet_splash',
    'droplet',
    'droplets',
    'duel',
    'egg_pod',
    'egg',
    'eggplant',
    'emerald',
    'energise',
    'explosion',
    'explosive_materials',
    'eye_monster',
    'eye_shield',
    'eyeball',
    'fairy_wand',
    'fairy',
    'fall_down',
    'falling',
    'fast_ship',
    'feather_wing',
    'feathered_wing',
    'fedora',
    'fire_bomb',
    'fire_breath',
    'fire_ring',
    'fire_shield',
    'fire_symbol',
    'fire',
    'fireball_sword',
    'fish',
    'fizzing_flask',
    'flame_symbol',
    'flaming_arrow',
    'flaming_claw',
    'flaming_trident',
    'flask',
    'flat_hammer',
    'flower',
    'flowers',
    'fluffy_swirl',
    'focused_lightning',
    'food_chain',
    'footprint',
    'forging',
    'forward',
    'fox',
    'frost_emblem',
    'frostfire',
    'frozen_arrow',
    'gamepad_cross',
    'gavel',
    'gear_hammer',
    'gear_heart',
    'gears',
    'gecko',
    'gem_pendant',
    'gem',
    'gemini',
    'glass_heart',
    'gloop',
    'gold_bar',
    'grappling_hook',
    'grass_patch',
    'grass',
    'grenade',
    'groundbreaker',
    'guarded_tower',
    'guillotine',
    'halberd',
    'hammer_drop',
    'hammer',
    'hand_emblem',
    'hand_saw',
    'hand',
    'harpoon_trident',
    'health_decrease',
    'health_increase',
    'health',
    'heart_bottle',
    'heart_tower',
    'heartburn',
    'hearts_card',
    'hearts',
    'heat_haze',
    'heavy_fall',
    'heavy_shield',
    'helmet',
    'help',
    'hive_emblem',
    'hole_ladder',
    'honeycomb',
    'hood',
    'horn_call',
    'horns',
    'horseshoe',
    'hospital_cross',
    'hot_surface',
    'hourglass',
    'hydra_shot',
    'hydra',
    'ice_cube',
    'implosion',
    'incense',
    'insect_jaws',
    'interdiction',
    'jetpack',
    'jigsaw_piece',
    'kaleidoscope',
    'kettlebell',
    'key_basic',
    'key',
    'kitchen_knives',
    'knife_fork',
    'knife',
    'knight_helmet',
    'kunai',
    'lantern_flame',
    'large_hammer',
    'laser_blast',
    'laser_site',
    'lava',
    'leaf',
    'leo',
    'level_four_advanced',
    'level_four',
    'level_three_advanced',
    'level_three',
    'level_two_advanced',
    'level_two',
    'lever',
    'libra',
    'light_bulb',
    'lighthouse',
    'lightning_bolt',
    'lightning_storm',
    'lightning_sword',
    'lightning_trio',
    'lightning',
    'lion',
    'lit_candelabra',
    'load',
    'locked_fortress',
    'love_howl',
    'maggot',
    'magnet',
    'mass_driver',
    'match',
    'meat_hook',
    'meat',
    'medical_pack',
    'metal_gate',
    'microphone',
    'mine_wagon',
    'mining_diamonds',
    'mirror',
    'monster_skull',
    'montains',
    'moon_sun',
    'mp_5',
    'muscle_fat',
    'muscle_up',
    'musket',
    'nails',
    'nodular',
    'noose',
    'nuclear',
    'ocarina',
    'ocean_emblem',
    'octopus',
    'omega',
    'on_target',
    'ophiuchus',
    'overhead',
    'overmind',
    'palm_tree',
    'pawn',
    'pawprint',
    'perspective_dice_five',
    'perspective_dice_four',
    'perspective_dice_one',
    'perspective_dice_random',
    'perspective_dice_six_two',
    'perspective_dice_six',
    'perspective_dice_three',
    'pill',
    'pills',
    'pine_tree',
    'ping_pong',
    'pisces',
    'plain_dagger',
    'player_despair',
    'player_dodge',
    'player_king',
    'player_lift',
    'player_pain',
    'player_pyromaniac',
    'player_shot',
    'player_teleport',
    'player_thunder_struck',
    'player',
    'podium',
    'poison_cloud',
    'potion',
    'pyramids',
    'queen_crown',
    'quill_ink',
    'rabbit',
    'radar_dish',
    'radial_balance',
    'radioactive',
    'raven',
    'reactor',
    'recycle',
    'regeneration',
    'relic_blade',
    'repair',
    'reverse',
    'revolver',
    'rifle',
    'ringing_bell',
    'roast_chicken',
    'robot_arm',
    'round_bottom_flask',
    'round_shield',
    'rss',
    'rune_stone',
    'sagittarius',
    'sapphire',
    'satellite',
    'save',
    'scorpio',
    'scroll_unfurled',
    'scythe',
    'sea_serpent',
    'seagull',
    'shark',
    'sheep',
    'sheriff',
    'shield',
    'ship_emblem',
    'shoe_prints',
    'shot_through_the_heart',
    'shotgun_shell',
    'shovel',
    'shuriken',
    'sickle',
    'sideswipe',
    'site',
    'skull_trophy',
    'skull',
    'slash_ring',
    'small_fire',
    'snail',
    'snake',
    'snorkel',
    'snowflake',
    'soccer_ball',
    'spades_card',
    'spades',
    'spawn_node',
    'spear_head',
    'speech_bubble',
    'speech_bubbles',
    'spider_face',
    'spikeball',
    'spiked_mace',
    'spiked_tentacle',
    'spinning_sword',
    'spiral_shell',
    'splash',
    'spray_can',
    'sprout_emblem',
    'sprout',
    'stopwatch',
    'suckered_tentacle',
    'suits',
    'sun_symbol',
    'sun',
    'sunbeams',
    'super_mushroom',
    'supersonic_arrow',
    'surveillance_camera',
    'syringe',
    'target_arrows',
    'target_laser',
    'targeted',
    'taurus',
    'telescope',
    'tentacle',
    'tesla',
    'thorn_arrow',
    'thorny_vine',
    'three_keys',
    'tic_tac_toe',
    'toast',
    'tombstone',
    'tooth',
    'torch',
    'tower',
    'trail',
    'trefoil_lily',
    'trident',
    'triforce',
    'trophy',
    'turd',
    'two_dragons',
    'two_hearts',
    'uncertainty',
    'underhand',
    'unplugged',
    'vase',
    'venomous_snake',
    'vest',
    'vial',
    'vine_whip',
    'virgo',
    'water_drop',
    'wifi',
    'wireless_signal',
    'wolf_head',
    'wolf_howl',
    'wooden_sign',
    'wrench',
    'wyvern',
    'x_mark',
    'zebra_shield',
    'zigzag_leaf',
  ];

  ngOnInit(): void {
    this.term.valueChanges.pipe(
      throttleTime(64),
      filter((term) => term && term.length > 0),
      map((term) => this.icons.filter((element) => element.indexOf(term) !== -1)),
    ).subscribe((results) => {
      this.results = results;
      }
    );
  }

  clear() {
    this.results.length = 0;
  }
}
